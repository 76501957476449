import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineLoading } from "react-icons/ai";
import { notNullish } from "react-select/dist/declarations/src/utils";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/auth";
import { useFormModal } from "../../contexts/formModal";
import { postOrders } from "../../services/orders";
import { getWhiteList, getWhiteListOnlyNames } from "../../services/whiteList";
import { Form, Column, RowModal, Row, Button } from "./style";

interface IReload {
  getTableFilds: () => void;
  getTableFildsPending: () => void;
  getCurrentBalance: () => void;
}

const LabelModal: React.FC<IReload> = ({
  getTableFilds,
  getCurrentBalance,
  getTableFildsPending,
}) => {
  const { user } = useAuth();
  const { closeModal } = useFormModal();
  const [coin, setCoin] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rsiStatus, setRsiStatus] = useState(false);
  const [newOrder, setNewOrder] = useState({
    estrategia: "automatizada",
    valor: 0,
    lucroEsperado: 1,
    trailingDelta: 1,
    trailingStop: false,
    isRsi: false,
    userId: user ? user.id : "",
    moeda: "",
    interval: "15m",
    target: "70-30",
  });

  useEffect(() => {
    getCoin();
  }, []);

  const getCoin = async () => {
    try {
      const result = await getWhiteListOnlyNames();

      if (result) {
        const filterCoin = result.map((item: any) => item.observacao);
        setCoin(filterCoin);
      }
    } catch (error) {
      console.error("Erro", error);
    }
  };

  const handleNewOrder = (key: string, value: any) => {
    if (key === "valor") {
      if (value <= 0) {
        toast.error("O valor não pode ser inferior a 1");
      } else {
        setNewOrder((oldValue) => {
          return { ...oldValue, [key]: value };
        });
      }
    } else {
      setNewOrder((oldValue) => {
        return { ...oldValue, [key]: value };
      });
    }
  };

  const postOrder = async (ev: any) => {
    ev.preventDefault();
    if (handleValidate()) {
      if (user?.satp) {
        setLoading(true);
        const _newOrder = newOrder;
        const valor = _newOrder.valor.toString();
        const {
          estrategia,
          lucroEsperado,
          userId,
          moeda,
          trailingDelta,
          trailingStop,
          isRsi,
          interval,
          target,
        } = _newOrder;

        try {
          const payload: any = {
            userId: userId,
            lucroEsperado: lucroEsperado,
            estrategia: estrategia,
            valor: valor,
            trailingStop: trailingStop,
            isRsi: isRsi,
          };
          if (estrategia === "personalizada") {
            payload["moeda"] = moeda;
            if (isRsi === true) {
              payload["interval"] = interval;
              payload["target"] = target;
            }
            if (trailingStop === true) {
              payload["trailingDelta"] = trailingDelta;
            }

            const result = await postOrders(payload);
            if (result.status === 201) {
              toast.success("Salvo com sucesso");
              closeModal();
              getCurrentBalance();
              getTableFilds();
              getTableFildsPending();
              setLoading(false);
            }
          } else {
            if (trailingStop === true) {
              payload["trailingDelta"] = trailingDelta;
            }
            const result = await postOrders(payload);
            if (result.status === 201) {
              toast.success("Salvo com sucesso");
              closeModal();
              getCurrentBalance();
              getTableFilds();
              getTableFildsPending();
              setLoading(false);
            }
          }
        } catch (error: any) {
          toast.error(error.message);
          closeModal();
          // toast.error("Erro ao criar nova ordem.");
        }
      } else {
        toast.error("Cadastre o Token de acesso no perfil do usuário");
        setLoading(false);
      }
    }
  };

  const handleValidate = () => {
    if (newOrder.valor < 12) {
      toast.error("Digite um valor válido.");
    } else if (
      newOrder.estrategia !== "automatizada" &&
      newOrder.moeda === ""
    ) {
      toast.error("Selecione a moeda ou altere a opção de entrada.");
    } else if (newOrder.lucroEsperado < 1) {
      toast.error("O valor do alvo não pode ser inferior a 1.");
    } else if (newOrder.trailingStop === true) {
      if (newOrder.trailingDelta > newOrder.lucroEsperado) {
        toast.error(
          "O valor de distancia do Trailing Stop não pode ser superior ao alvo."
        );
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleStatusTralingRsi = (field: string) => {
    setNewOrder({
      ...newOrder,
      [field]:
        field === "trailingStop" ? !newOrder.trailingStop : !newOrder.isRsi,
    });
  };

  const handleStatusRsi = () => {
    setRsiStatus(!rsiStatus);
    if (rsiStatus === true) {
      setNewOrder({ ...newOrder, isRsi: false });
    }
  };

  return (
    <Form onSubmit={(ev) => postOrder(ev)}>
      <Column>
        <RowModal center={"center"}>
          {/* <Column> */}
          <label htmlFor="estrategia">Opções de Entrada</label>
          <select
            name="estrategia"
            id="estrategia"
            onChange={(ev) => handleNewOrder("estrategia", ev.target.value)}
          >
            <option value="automatizada">Automatizada</option>
            <option value="personalizada">Personalizada</option>
          </select>
          {/* </Column> */}
          {/* {newOrder.estrategia !== "automatizada" && (
            <Column>
              <label htmlFor="rsi">Indicador RSI</label>
              <Row style={{ padding: 0 }}>
                <label
                  style={{ marginRight: 20, fontSize: 16 }}
                  onClick={() => setRsi(true)}
                >
                  <input type="radio" name="rsi" id="rsi" checked={rsi} />
                  &nbsp;Sim
                </label>
                <label
                  style={{ marginRight: 20, fontSize: 16 }}
                  onClick={() => setRsi(false)}
                >
                  <input
                    type="radio"
                    name="rsi"
                    id="rsi"
                    checked={rsi == false ? true : false}
                  />
                  &nbsp;Não
                </label>
              </Row>
            </Column>
          )} */}
        </RowModal>
        <hr />
        {newOrder.estrategia === "personalizada" && (
          <RowModal>
            <Column>
              <label htmlFor="moeda">Moeda</label>
              <input
                className="datalist"
                list="moeda"
                type="search"
                name="myMoeda"
                id="myMoeda"
                autoComplete="chrome-off"
                placeholder="Selecione..."
                onChange={(ev) => handleNewOrder("moeda", ev.target.value)}
              />
              <datalist id="moeda">
                {coin &&
                  coin.map((item: any, index: any) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
              </datalist>
            </Column>
          </RowModal>
        )}
        <RowModal>
          <Column>
            <label htmlFor="valor">
              {newOrder.estrategia !== "trailingStop"
                ? "Valor Entrada"
                : "Preço Ativação"}
            </label>
            <div className="input-group">
              <input
                type="number"
                placeholder={
                  newOrder.estrategia !== "trailingStop" ? "Mínimo $12" : "$"
                }
                name="valor"
                id="valor"
                value={newOrder.valor > 0 ? newOrder.valor : ""}
                onChange={(ev) => handleNewOrder("valor", +ev.target.value)}
              />
              <span>USDT</span>
            </div>
          </Column>
        </RowModal>
        <RowModal>
          {newOrder.estrategia === "trailingStop" ? (
            <Column>
              <label htmlFor="trailingDelta">T/D</label>
              <div className="input-group">
                <input
                  type="number"
                  id="trailingDelta"
                  placeholder="1"
                  value={newOrder.trailingDelta}
                  onChange={(ev) =>
                    handleNewOrder("trailingDelta", ev.target.value)
                  }
                />
                <span className="input-group-addon">%</span>
              </div>
            </Column>
          ) : (
            <Column>
              <label htmlFor="lucroEsperado">Alvo</label>
              <div className="input-group">
                <input
                  type="number"
                  id="lucroEsperado"
                  placeholder="1"
                  min={1}
                  onChange={(ev) =>
                    handleNewOrder("lucroEsperado", +ev.target.value)
                  }
                />
                <span className="input-group-addon">%</span>
              </div>
            </Column>
          )}
        </RowModal>
        <RowModal>
          <div className="input-group input-trailing">
            <input
              type="checkbox"
              name="trailing"
              id="trailing"
              onChange={() => handleStatusTralingRsi("trailingStop")}
            />
            <label htmlFor="trailing">Trailing Stop</label>
          </div>
        </RowModal>
        {newOrder.trailingStop == true && (
          <RowModal>
            <Column>
              <label htmlFor="trailingDelta">Distancia Trailing Stop</label>
              <div className="input-group">
                <input
                  type="number"
                  name="trailingDelta"
                  id="trailingDelta"
                  placeholder="1"
                  min={1}
                  onChange={(ev) =>
                    handleNewOrder("trailingDelta", +ev.target.value)
                  }
                />
                <span className="input-group-addon">%</span>
              </div>
            </Column>
          </RowModal>
        )}
        {newOrder.estrategia === "personalizada" ? (
          <>
            <div className="indicadores">
              <label>
                <div className="switch-wrapper">
                  <input
                    type="checkbox"
                    name="wrapper"
                    checked={rsiStatus}
                    onClick={() => handleStatusRsi()}
                  />
                  <span className="switch-button"></span>
                </div>
                Indicadores
              </label>
            </div>
            {rsiStatus === true && (
              <RowModal>
                <div className="input-group input-trailing">
                  <input
                    type="checkbox"
                    name="isRsi"
                    id="isRsi"
                    checked={newOrder.isRsi}
                    onChange={() => handleStatusTralingRsi("isRsi")}
                  />
                  <label htmlFor="isRsi">RSI (Indice de Força Relativa)</label>
                </div>
              </RowModal>
            )}
            {newOrder.isRsi == true && rsiStatus === true && (
              <>
                <RowModal>
                  <Column>
                    <label>Pedíodo Gráfico</label>
                    <select
                      className="large"
                      name="interval"
                      id="interval"
                      onChange={(ev) =>
                        handleNewOrder("interval", ev.target.value)
                      }
                    >
                      <option value="15m" selected>
                        15 Minutos
                      </option>
                      <option value="1h">1 Hora</option>
                      <option value="2h">2 Horas</option>
                      <option value="4h">4 Horas</option>
                      <option value="1d">1 Dia</option>
                      <option value="1w">1 Semana</option>
                      <option value="1M">1 Mês</option>
                    </select>
                  </Column>
                </RowModal>
                <RowModal>
                  <Column>
                    <label htmlFor="target">Configuração</label>
                    <select
                      name="target"
                      id="target"
                      className="large"
                      onChange={(ev) =>
                        handleNewOrder("target", ev.target.value)
                      }
                    >
                      <option value="70-30" selected>
                        70 - 30
                      </option>
                      <option value="80-20">80 - 20</option>
                    </select>
                  </Column>
                </RowModal>
              </>
            )}
          </>
        ) : null}

        <Row className="flex-end">
          <Button
            type="button"
            wide={"auto"}
            background={"var(--warning)"}
            disabled={loading}
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            wide={"auto"}
            background={"var(--primary)"}
            disabled={loading}
          >
            Adicionar Ordem &nbsp;
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
        </Row>
      </Column>
    </Form>
  );
};

export default LabelModal;
