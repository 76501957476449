import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Form,
  Input,
  Button,
  Link,
  FormContainer,
  Logo,
  Title,
  Subtitle,
  Row,
  ColumnInput,
} from "./styles";

import LogoImage from "../../assets/images/logo_rizar.png";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading,
} from "react-icons/ai";
import Toast from "../../components/Toast";
import useFetch from "../../hooks/useFetch";
import { resetPassword, validateToken } from "../../services/auth";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const token = query.get("token");
    if (token) {
      setPayload({
        ...payload,
        token: token,
      });
      handleValidateToken(token);
    }
  }, []);

  const handleValidateToken = async (token: any) => {
    try {
      const response = await validateToken(token);
      if (response) {
        setValidToken(response.valid);
      }
    } catch (err: any) {
      Toast.show(err.message, "error");
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      setLoading(true);
      try {
        const result = await resetPassword(payload);
        if (result.status === 201) {
          Toast.show("Senha redefinida com sucesso", "success");
          history.push("/login");
          setLoading(false);
        }
      } catch (err: any) {
        Toast.show(err.message, "error");
        setLoading(false);
      }
    }
  };

  const validate = () => {
    if (payload.password === "") {
      Toast.show("O campo nova senha é obrigatório", "error");
    } else if (payload.password.length < 8) {
      Toast.show("A senha precisa ter no mínimo 8 dígitos", "error");
    } else if (payload.confirmPassword === "") {
      Toast.show("O campo confirmar senha é obrigatório", "error");
    } else if (payload.password !== payload.confirmPassword) {
      Toast.show(
        "O campo confirmar senha deve ser igual ao campo nova senha",
        "error"
      );
    } else {
      return true;
    }
  };

  const handlePayload = (key: string, value: string) => {
    setPayload({
      ...payload,
      [key]: value,
    });
  };

  return (
    <Container>
      <FormContainer>
        <Logo src={LogoImage} />
        {validToken ? (
          <>
            <Title>Recuperar senha</Title>
            <Subtitle>Por favor, insira a nova senha</Subtitle>
            <Form autoComplete="off">
              <ColumnInput>
                <button type="button" onClick={() => setPassword(!password)}>
                  {password ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </button>
                <Input
                  wide="100%"
                  id="new_password"
                  name="new_password"
                  placeholder="Digite sua senha..."
                  label="Nova Senha"
                  type={password ? "text" : "password"}
                  value={payload.password}
                  onChange={({ target }) =>
                    handlePayload("password", target.value)
                  }
                />
              </ColumnInput>
              <ColumnInput>
                <button
                  type="button"
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  {confirmPassword ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}
                </button>
                <Input
                  wide="100%"
                  id="confirm_password"
                  name="confirm_password"
                  placeholder="Digite sua senha novamente..."
                  label="Confirmar Senha"
                  type={confirmPassword ? "text" : "password"}
                  value={payload.confirmPassword}
                  onChange={({ target }) =>
                    handlePayload("confirmPassword", target.value)
                  }
                />
              </ColumnInput>
              <Button
                disabled={loading}
                variant="filled"
                type="button"
                onClick={() => handleSubmit()}
              >
                Enviar &nbsp;
                {loading && (
                  <span>
                    <AiOutlineLoading />
                  </span>
                )}
              </Button>
              <Row>
                <Link variant="outlined" to={{ pathname: "/login" }}>
                  Login
                </Link>
                <Link variant="outlined" to={{ pathname: "/cadastro" }}>
                  Cadastre-se
                </Link>
              </Row>
            </Form>
          </>
        ) : (
          <>
            <div className="token">
              <span>Token Inválido</span>
            </div>
            <Row>
              <Link variant="outlined" to={{ pathname: "/login" }}>
                Login
              </Link>
              <Link variant="outlined" to={{ pathname: "/cadastro" }}>
                Cadastre-se
              </Link>
            </Row>
          </>
        )}
      </FormContainer>
    </Container>
  );
};

export default ForgotPassword;
