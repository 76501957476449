/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import format from "date-fns/format";
import { Button } from "../styles";
import { ptBR } from "date-fns/locale";
interface ICellProps {
  campo?: any;
  type?: string;
  options?: Array<string>;
  editavel: boolean;
  mascara?: string;
  value: string | boolean;
  precisao?: number;
  moeda?: string;
  onChange?: (value: string) => void;
}

interface IRow {
  precoAtual?: string;
  profitLoss?: string;
  profitLossPercent?: string;
  lucroLiquido?: string;
  lucroPercent?: string;
}

const CellComponent: React.FC<ICellProps> = ({
  campo,
  type = "texto",
  value,
  options,
  editavel,
  mascara,
  precisao = 2,
  moeda = "BRL",
  onChange = () => {},
}) => {
  const [cellValue, setCellValue] = useState<any>(value);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (typeof cellValue === "object") {
      onChange(cellValue);
    } else {
      onChange(cellValue as string);
    }
  }, [cellValue]);

  useEffect(() => {
    setCellValue(value);
  }, [value]);

  const styleRow = (value: any, campo: any, mascara?: any) => {
    let resp = "";
    if (
      [
        "precoAtual",
        "lucroPercent",
        "profitLoss",
        "profitLossPercent",
      ].includes(campo) ||
      mascara === "dinheiro" ||
      mascara === "percentual"
    ) {
      if (value && typeof (value === "string")) {
        let _value = value ? value.toString() : null;

        if (_value.includes("%")) {
          let filterValue = Number(value.replace("%", ""));

          if (filterValue < 0) {
            resp = "danger";
          } else if (filterValue >= 0) {
            resp = "green";
          }
        } else {
          let filterValue = Number(value);
          if (!isNaN(filterValue)) {
            if (filterValue < 0) {
              resp = "danger";
            } else if (filterValue >= 0) {
              resp = "green";
            }
          }
        }
      }
    } else if (["emissao", "aprovacao", "cancelamento"].includes(campo)) {
      resp = "center";
    } else {
      if (mascara === "percentual") {
        resp = "white right";
      } else {
        resp = "white";
      }
    }

    return resp;
  };

  const styleCheckbox = (value: any, campo: any) => {
    if (campo === "baixado") {
      if (value === true) {
        return "green";
      } else {
        return "danger";
      }
    } else if (campo === "excluido") {
      if (value === true) {
        return "danger";
      } else if (value === false) {
        return "green";
      } else {
        return "white";
      }
    } else if (campo === "zRecebeNotificacaoTelegram") {
      if (value === true) {
        return "green";
      } else if (value === false) {
        return "danger";
      } else {
        return "white";
      }
    } else if (campo === "zBinanceApiActivated") {
      if (value === false || value === null) {
        return "danger";
      } else {
        return "green";
      }
    } else if (campo === "bloqueadoEm") {
      if (cellValue === null) {
        return "green";
      } else {
        return "danger";
      }
    } else if (value === true) {
      return "danger";
    } else {
      return "green";
    }
  };

  const cellValueCheckout = (campo?: string, value?: any) => {
    if (campo === "baixado") {
      if (value === false) {
        return "NÃO";
      } else if (value === true) {
        return "SIM";
      } else {
        return "---";
      }
    } else if (campo === "excluido") {
      if (value === true) {
        return "Sim";
      } else if (value === false) {
        return "Não";
      } else {
        return "---";
      }
    } else if (campo === "zRecebeNotificacaoTelegram") {
      if (value === false) {
        return "Desativado";
      } else if (value === true) {
        return "Ativo";
      } else {
        return "---";
      }
    } else if (campo === "zBinanceApiActivated") {
      if (value === false || value === null) {
        return "Desativado";
      } else if (value == true) {
        return "Ativo";
      }
    } else if (campo === "bloqueadoEm") {
      if (value === null) {
        return "Não";
      } else {
        return "Sim";
      }
    } else if (value) {
      return "Desativado";
    } else if (value == false) {
      return "Ativo";
    }
  };

  const renderCell = () => {
    switch (type) {
      case "texto":
        return focus && editavel ? (
          <input
            type="text"
            value={cellValue as string}
            onChange={(e) => setCellValue(e.target.value)}
            onBlur={(e) => setFocus(false)}
            readOnly={!editavel}
          />
        ) : (
          <p
            onClick={(e) => setFocus(true)}
            className={styleRow(cellValue, campo, mascara)}
          >
            {cellValue
              ? mascara
                ? mascara.startsWith("data")
                  ? format(new Date(cellValue as string), "dd/MM/yyyy HH:mm")
                  : mascara === "dinheiro"
                  ? new Intl.NumberFormat([], {
                      style: "currency",
                      currency: moeda,
                      minimumFractionDigits: precisao,
                    }).format(cellValue)
                  : mascara === "percentual"
                  ? `${cellValue}%`
                  : cellValue
                : cellValue
              : "---"}
          </p>
        );
      case "checkbox":
        return focus && editavel ? (
          <input
            type="checkbox"
            checked={cellValue ? true : false}
            onChange={() => {
              cellValue ? setCellValue("") : setCellValue("1");
            }}
            onBlur={(e) => setFocus(false)}
            readOnly={!editavel}
          />
        ) : (
          <p
            onClick={(e) => setFocus(true)}
            className={styleCheckbox(cellValue, campo)}
          >
            {campo || cellValue ? cellValueCheckout(campo, cellValue) : null}
          </p>
        );
      case "lookup":
        return (
          <p>
            {typeof cellValue === "object"
              ? cellValue
                ? cellValue.nome
                  ? cellValue.nome
                  : cellValue.codigo
                  ? cellValue.codigo
                  : cellValue.id
                : "---"
              : cellValue}
          </p>
        );
      case "select":
        return focus && editavel && options ? (
          <select
            name="select"
            defaultValue={cellValue as string}
            onBlur={(e) => setFocus(false)}
            disabled={!editavel}
          >
            {options.map((option) => {
              return <option value={option}>{option}</option>;
            })}
          </select>
        ) : (
          <p onClick={(e) => setFocus(true)}>{cellValue ? cellValue : "---"}</p>
        );
      case "button":
        return (
          <Button wide="auto">
            <a href={cellValue} target="_blank">
              Imprimir 2º via
            </a>
          </Button>
        );
      case "upload":
        return focus && editavel ? (
          <input
            type="file"
            value=""
            onChange={() => {}}
            onBlur={(e) => setFocus(false)}
            readOnly={!editavel}
          />
        ) : cellValue ? (
          <img
            src={cellValue}
            width="120"
            alt={cellValue}
            onClick={(e) => setFocus(true)}
          />
        ) : (
          <p onClick={(e) => setFocus(true)}>---</p>
        );
      default:
        return focus && editavel ? (
          <input
            type="text"
            value={cellValue as string}
            onChange={(e) => setCellValue(e.target.value)}
            onBlur={(e) => setFocus(false)}
            readOnly={!editavel}
          />
        ) : (
          <p
            onClick={(e) => setFocus(true)}
            className={styleRow(cellValue, campo)}
          >
            {cellValue
              ? mascara
                ? mascara.startsWith("data")
                  ? format(new Date(cellValue as string), "dd/MM/yyyy HH:mm")
                  : mascara === "dinheiro"
                  ? new Intl.NumberFormat([], {
                      style: "currency",
                      currency: "USD",
                    }).format(cellValue)
                  : mascara === "percentual"
                  ? `${cellValue}%`
                  : cellValue
                : cellValue
              : "---"}
          </p>
        );
    }
  };

  return <div>{renderCell()}</div>;
};

export default CellComponent;
